import React from 'react';

import './Header.component.scss';
import { translate } from 'app/core';

import { Header as HeaderNhs } from 'nhsuk-react-components';
import { useLocation } from 'react-router-dom';
import configuration from 'config';

const Header = (): JSX.Element => {
  const { chUrl, packageId } = configuration;
  const { pathname } = useLocation<{ pathname: string }>();
  const chUrlLogin = `${chUrl}/auth?package=${packageId}`

  const isRegistedPage = pathname === '/registered'

  const onLogin = (e: any): void => {
    e.preventDefault();
    if (window.gtag) {
      // Send ‘Login’ button clicks tracking event and then redirect to URL.
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11031067820/CIo5CJ6qsogYEKz5gowp',
      });
    }
    window.location.href = chUrlLogin;
  };

  return (
    <HeaderNhs orgName={translate('HEADER.TITLE')} className="nhsuk-u-margin-bottom-5">
      <HeaderNhs.Container>
        <div className="nhsuk-header__logo">
          <a className="nhsuk-header__link" href="/" aria-label="NHS homepage">
            <svg className="nhsuk-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 16">
              <path className="nhsuk-logo__background" d="M0 0h40v16H0z" />
              <path className="nhsuk-logo__text" d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6" />
            </svg>
            <span className="nhsuk-organisation-name">{translate('HEADER.TITLE')}</span>
          </a>
        </div>
        <HeaderNhs.Content className="header-nhs-content">
          {
            !isRegistedPage
            ? (
              <>
                <span className="nhsuk-u-margin-right-3 nhsuk-body">{translate('HOME.ALREADY_REGISTERED')}</span>
                <a href={chUrlLogin} type="button" className="btn-login" onClick={(e) => onLogin(e)}>
                  {translate('HOME.LOGIN')}
                </a>
              </>
          )
            : null
          }
        </HeaderNhs.Content>
      </HeaderNhs.Container>
    </HeaderNhs>
  )
};

export default Header;
