import React from 'react';
import { ActionLink, Label } from 'nhsuk-react-components';
import { translate } from 'app/core';
import './NoTypeDiabetesView.component.scss';
import {Link} from "react-router-dom";

const ViewComponent = ({children}: {children: React.ReactNode}): JSX.Element => {
  return (
    <div className="bg-white w-full mx-auto p-4 lg:p-8 rounded shadow-xl lg:max-w-5xl nhsuk-u-margin-bottom-4">
      <div className="mr-0 p-4 lg:p-8 mx-auto">
        {children}
      </div>
    </div>
  );
};

const Type1ViewComponent = (): JSX.Element => {

  return (
    <ViewComponent>
      <Label size="l" className="label-blue">{translate('TYPE_1.TITLE')}</Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('TYPE_1.DESC_0_1')}
          <Link className="nhsuk-link" to="/">{translate('TYPE_1.DESC_0_2')}</Link>
          {translate('TYPE_1.DESC_0_3')}
        </p>

        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('TYPE_1.DESC_1')}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('TYPE_1.DESC_2')}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('TYPE_1.DESC_3')}
        </p>
      </section>
      <ActionLink href="https://www.nhs.uk/conditions/type-1-diabetes/" target="_blank" rel="noreferrer">
        <span>{translate('TYPE_1.VISIT_PAGE')}</span>
        <span className="visually-hidden">{translate('OPENS_IN_NEW_TAB')}</span>
      </ActionLink>
    </ViewComponent>
  );
};

const RiskType2ViewComponent = (): JSX.Element => {
  return (
    <ViewComponent>
      <Label size="l" className="label-blue">{translate('RISK_TYPE_2.HL_PROGAMME_AVAILABLE_TO')}</Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('RISK_TYPE_2.DESC_0_1')}
          <Link className="nhsuk-link" to="/">{translate('RISK_TYPE_2.DESC_0_2')}</Link>
          {translate('RISK_TYPE_2.DESC_0_3')}
        </p>
          
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('RISK_TYPE_2.DESC_1')}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('RISK_TYPE_2.DESC_2')}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('RISK_TYPE_2.DESC_3')}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('RISK_TYPE_2.DESC_4')}
        </p>
      </section>
      <ActionLink href="https://riskscore.diabetes.org.uk/start" target="_blank" rel="noreferrer">
        <span>{translate('RISK_TYPE_2.RESOURCES_TYPE_2_DIABETES_LINK')}</span>
        <span className="visually-hidden">{translate('OPENS_IN_NEW_TAB')}</span>
      </ActionLink>
    </ViewComponent>
  );
}

export {
  Type1ViewComponent,
  RiskType2ViewComponent
};
