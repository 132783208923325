import {
  BaseLayout, ErrorLayout,
} from 'app/shared/layouts';
import { RouteInterface } from 'app/core/interfaces';
import {
  P404Component,
  HomeView,
  RegisteredView,
  PoliciesView,
  AgeView,
  RiskType2ViewComponent,
  Type1ViewComponent
} from 'app/views';

const routes: Array<RouteInterface> = [
  {
    path: '/',
    exact: true,
    layout: BaseLayout,
    component: HomeView,
  },
  {
    path: '/registered',
    exact: true,
    layout: BaseLayout,
    component: RegisteredView,
  },
  {
    path: '/type1',
    exact: true,
    layout: BaseLayout,
    component: Type1ViewComponent,
  },
  {
    path: '/risktype2',
    exact: true,
    layout: BaseLayout,
    component: RiskType2ViewComponent,
  },
  {
    path: '/age',
    exact: true,
    layout: BaseLayout,
    component: AgeView,
  },
  // Policies
  {
    path: '/policies/:slug',
    exact: true,
    layout: BaseLayout,
    component: PoliciesView,
  },
  {
    path: '*',
    name: 'Error 404',
    layout: ErrorLayout,
    component: P404Component,
  },
];

export default routes;
