import { translate } from 'app/core';
import { DescribesYouOptions } from 'app/shared/models/registerForm';
import { ActionLink, Label } from 'nhsuk-react-components';
import React from 'react';
import { useParams } from 'react-router-dom';

const RegisteredViewComponent = (): JSX.Element => {
  const { type } = useParams<{ type: string }>();

  return (
    <div className="nhsuk-u-margin-bottom-4 nhsuk-u-margin-top-4">
      <div className="bg-white w-full lg:w-9/12 mx-auto p-4 lg:p-8 rounded shadow-xl lg:max-w-5xl">
        <div className="mr-0 lg:mr-4 mx-auto">
          <Label size="m">{translate('REGISTERED.CHECK_YOUR_EMAIL')}</Label>
          <section>
            <ul className="nhsuk-list nhsuk-list--bullet">
              <li>{translate('REGISTERED.CHECK_YOUR_EMAIL_1')}</li>
              <li>{translate('REGISTERED.CHECK_YOUR_EMAIL_2')}</li>
              <li>
                {translate('REGISTERED.CHECK_YOUR_EMAIL_3')}
                {translate('REGISTERED.NO_REPLY_EMAIL')}
              </li>
            </ul>
            <p className="nhsuk-body nhsuk-u-margin-bottom-3 font-bold">
              {translate('REGISTERED.IF_NOT_RECEIVED')}
            </p>
            <p className="nhsuk-body nhsuk-u-margin-bottom-3">
              {translate('REGISTERED.EMAIL_US_FOR_HELP')}
              <a href="mailto:healthyliving@support.changinghealth.com">{translate('REGISTERED.EMAIL_US_FOR_HELP_LINK')}</a>
            </p>
          </section>
          {
            type as DescribesYouOptions === DescribesYouOptions.Type2
            ? (
              <>
                <Label size="m">{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_TITLE')}</Label>
                <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
                  <section>
                    <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_1')}</p>
                    <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_2')}</p>
                    <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_3')}</p>
                  </section>
                  <ActionLink href="https://riskscore.diabetes.org.uk/start" target="_blank" rel="noreferrer">
                    <span>{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_LINK')}</span>
                    <span className="visually-hidden">{translate('OPENS_IN_NEW_TAB')}</span>
                  </ActionLink>
                </div>
              </>
            )
            : null

          }

          {
            type as DescribesYouOptions === DescribesYouOptions.Type1
            ? (
              <>
                <Label size="m">{translate('HOME.INFO.RESOURCES_TYPE_1_DIABETES_TITLE')}</Label>
                <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
                  <section>
                    <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_1_DIABETES')}</p>
                  </section>
                  <ActionLink href="https://www.nhs.uk/conditions/type-1-diabetes/">{translate('HOME.INFO.RESOURCES_TYPE_1_DIABETES_LINK')}</ActionLink>
                </div>
              </>
            )
            : null

          }
        </div>
      </div>
      <noscript>
        <img src="https://ad.doubleclick.net/ddm/activity/src=13047332;type=nhsyu0;cat=regis0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\$\{GDPR\};gdpr_consent=\$\{GDPR_CONSENT_755\};ord=1?" width="1" height="1" alt="" />
      </noscript>
    </div>
  );
};

export default RegisteredViewComponent;
