import React from 'react';
import { translate } from "app/core"
import configuration from "../../../../config";
import './AlreadyRegisteredSection.component.scss';

const AlreadyRegisteredSection = (): JSX.Element => {

    const {chUrl, packageId} = configuration;
    const chUrlLogin = `${chUrl}/auth?package=${packageId}`

    const onLogin = (e: any): void => {
        e.preventDefault();
        if (window.gtag) {
            // Send ‘Login’ button clicks tracking event and then redirect to URL.
            window.gtag('event', 'conversion', {
                'send_to': 'AW-11031067820/CIo5CJ6qsogYEKz5gowp',
            });
        }
        window.location.href = chUrlLogin;
    };

  return(
    <div className="mx-auto bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mt-8 mb-2">
      <h2 className="nhsuk-label--m">
        {translate('HOME.ALREADY_REGISTERED')}
      </h2>

      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('HOME.LOGIN_TO_HL')}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('HOME.YOU_WILL_NEED')}
        </p>
      </section>

      <div className="nhsuk-u-margin-top-6 text-left">
        <a href={chUrlLogin} type="button" className="btn-login" onClick={(e) => onLogin(e)}>
          {translate('HOME.LOGIN')}
        </a>
      </div>
    </div>
  )
}

export default AlreadyRegisteredSection;
