import React from 'react';
import { translate } from "app/core"

const InformationForHealthcareProfessionalSection = (): JSX.Element => {
    return(
      <div className="mx-auto bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mt-8 mb-2">
        <h2 className="nhsuk-label--m">
          {translate('HOME.INFO.HEALTH_CARE_PROFESSIONAL_TITLE')}
        </h2>
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.INFO.IF_YOU_ARE_HEALTHCARE_PROFESSIONAL')}
            <a href={`mailto:${translate('HOME.INFO.IF_YOU_ARE_HEALTHCARE_PROFESSIONAL_EMAIL')}`}>
              <span>{translate('HOME.INFO.IF_YOU_ARE_HEALTHCARE_PROFESSIONAL_EMAIL')}</span>
              <span className="visually-hidden">{translate('OPENS_IN_EMAIL_APP')}</span>
            </a>
            .
          </p>
        </section>
      </div>
    )
}

export default InformationForHealthcareProfessionalSection;
