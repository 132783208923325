/* eslint-disable @typescript-eslint/camelcase */
import configuration from 'config';
import { apiServiceInstance } from 'app/core/services';
import {
  ConsentGPOptions,
  DescribesYouOptions,
  GendersOptions,
  HowYouFindOptions,
  RegisterFormI,
  RequestI
} from '../models/registerForm';

export enum GendersOptionsDTO {
  'Male' = 0,
  'Female' = 1,
  'Intersex' = 2,
  'Prefer not to say' = 3
};
export enum DescribesYouOptionsDTO {
  'I have type 2 diabetes' = 1,
  'I’m at risk of type 2 diabetes' = 2,
  'I care for / support a person with type 2 diabetes' = 3,
  'I have type 1 diabetes' = 4,
  'None of the above' = 5,
  'I am a healthcare professional / I work in the diabetes field' = 6,
  'I am a friend or family member of someone living with type 2 diabetes' = 7,
};

export enum HowYouFindOptionsDTO {
  'Charity or community group - Diabetes UK'= 'Charity or community group - Diabetes UK',
  'Charity or community group - Other' = 'Charity or community group - Other',
  'Friend or family member' = 'Friend or family member',
  'Healthcare Professional - GP practice' = 'Healthcare Professional - GP practice',
  'Healthcare Professional – Other (e.g. community, hospital)' = 'Healthcare Professional – Other (e.g. community, hospital)',
  'Online search (e.g. Google, Bing, Yahoo)' = 'Online search',
  'Poster or leaflet' = 'Poster, leaflet or other NHS promotion',
  'Social media (e.g. Facebook, Twitter)' = 'Social media',
  'NHS UK website' = 'NHS UK website',
  'None of the above' = 'None of the above',
}
export enum ConsentGPOptionsDTO {
  'Yes' = 1,
  'No' = 2,
}

export class UserService {
  private readonly CHANGING_HEALTH_TOKEN = configuration.changingHealthToken;
  private apiUrl = configuration.apiUrl;
  readonly http = apiServiceInstance;

  async registerUser(registerForm: RegisterFormI): Promise<{ data: { user: { id: string}}}> {
    const month = registerForm.dateOfBirth.month.length === 2 ? registerForm.dateOfBirth.month : `0${registerForm.dateOfBirth.month}`;
    const day = registerForm.dateOfBirth.day.length === 2 ? registerForm.dateOfBirth.day : `0${registerForm.dateOfBirth.day}`
    const birthdate = registerForm.dateOfBirth ? `${registerForm.dateOfBirth.year}-${month}-${day}` : '';
    const postcode = registerForm.postcode ? `${registerForm.postcode.replace(/\u00A0/, ' ')}` : registerForm.postcode;
    const request: RequestI = {
      sys_first_name: registerForm.firstName,
      sys_last_name: registerForm.lastName,
      unit_id: configuration.unitId,
      package_id: configuration.packageId,
      email: registerForm.email,
      enabled: true,
      welcome_message: true,
      birthdate,
      pops: [
        {category: 'gender', key: 'gender-m-f-i-p', value: GendersOptionsDTO[registerForm.gender as GendersOptions]},
        {category: 'address', key: 'postcode', value: postcode},
        {category: 'diabetes-status', key: 'diabetes-status-healthy-living-update', value: DescribesYouOptionsDTO[registerForm.describesYou as DescribesYouOptions]},
        {category: 'other-details', key: 'find-out-about-healthy-living', value: HowYouFindOptionsDTO[registerForm.findOutAbout as HowYouFindOptions]},
        {category: 'consent', key: 'i-agree', value: registerForm.agree},
        {category: 'consent', key: 'consent-to-share-data-with-your-gp-practice', value: ConsentGPOptionsDTO[registerForm.consentGP as ConsentGPOptions]},
      ]
    }
    this.http.setToken(this.CHANGING_HEALTH_TOKEN);
    return this.http.post(
      `${this.apiUrl}`,
      { ...request },
    );
  }
}

const userServiceInstance = new UserService();

export default userServiceInstance;
