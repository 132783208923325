import { translate } from 'app/core';
import React from 'react';

const InfoComponent = (): JSX.Element => {
  return (
    <div className="mr-0 lg:mr-4 mx-auto mb-4">
      <h2 className="nhsuk-u-margin-bottom-4 nhsuk-label--m">
        {translate('HOME.INFO.WHAT_IS')}
      </h2>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('HOME.INFO.HEALTHY_LIVING_IS')}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('HOME.INFO.HEALTHY_LIVING_IT_INCLUDES')}
        </p>
        <ul className="nhsuk-list nhsuk-list--bullet">
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_1')}</li>
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_2')}</li>
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_3')}</li>
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_4')}</li>
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_5')}</li>
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_6')}</li>
        </ul>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('HOME.INFO.HEALTHY_LIVING_CAN_USE')}
        </p>
        <p className="nhsuk-u-margin-bottom-2 nhsuk-label--s nhsuk-u-margin-top-4">
          {translate('HOME.INFO.HEALTHY_LIVING_REGISTER_NOW')}
        </p>
        <p className="nhsuk-body">
          {translate('HOME.INFO.HEALTHY_LIVING_SIGN_UP_NOW')}
        </p>
      </section>
    </div>
  );
};

const GetHelpComponent = (): JSX.Element => {
  return (
    <div className="mr-0 lg:mr-4 mx-auto">
      <h2 className="nhsuk-label--m">
        {translate('HOME.INFO.GET_HELP')}
      </h2>

      <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.INFO.IF_YOU_NEED_HELP')}
          </p>

          <p className="nhsuk-body nhsuk-u-margin-bottom-3">

            <a href="mailto:healthyliving@support.changinghealth.com">
              <span>{translate('HOME.INFO.EMAIL_US_1')}</span>
              <span className="visually-hidden">{translate('OPENS_IN_EMAIL_APP')}</span>
            </a>
            {translate('HOME.INFO.EMAIL_US_2')}
          </p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.SUPPORT_TEAM_AVAILABLE')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.MEDICAL_ADVICE')}</p>

        </section>
      </div>

      <div className="nhsuk-u-margin-bottom-2 lg:mb-0">
        <p className="nhsuk-u-margin-bottom-4 nhsuk-label--s nhsuk-u-margin-top-4">
          {translate('HOME.INFO.MEDICAL_ADVICE_TITLE')}
        </p>
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.CONCERNED_ABOUT_YOUR_HEALTH')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.INFO.NEED_MEDICAL_HELP_NOW_1')}
            <a href="https://111.nhs.uk">{translate('HOME.INFO.NEED_MEDICAL_HELP_NOW_LINK')}</a>
            {translate('HOME.INFO.NEED_MEDICAL_HELP_NOW_2')}
          </p>
          <p className="nhsuk-body">{translate('HOME.INFO.DEAF_PEOPLE_CAN_USE_TEXT')}</p>


          <h2 className="nhsuk-u-margin-bottom-4 nhsuk-label--m nhsuk-u-margin-top-1">
            {translate('HOME.INFO.HEALTH_CARE_PROFESSIONAL_TITLE')}
          </h2>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.INFO.IF_YOU_ARE_HEALTHCARE_PROFESSIONAL')}
            <a href={`mailto:${translate('HOME.INFO.IF_YOU_ARE_HEALTHCARE_PROFESSIONAL_EMAIL')}`}>
              <span>{translate('HOME.INFO.IF_YOU_ARE_HEALTHCARE_PROFESSIONAL_EMAIL')}</span>
              <span className="visually-hidden">{translate('OPENS_IN_EMAIL_APP')}</span>
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

const CareForComponent = (): JSX.Element => {
  return (
    <div className="mr-0 lg:mr-4 mx-auto">
      <h2 className="nhsuk-label--m">
        {translate('HOME.INFO.CARE_FOR_TITLE')}
      </h2>

      <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.CARE_FOR_DESC_1')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.CARE_FOR_DESC_2')}</p>
        </section>
      </div>
    </div>
  );
};

const FamilyMemberComponent = (): JSX.Element => {
  return (
    <div className="mr-0 lg:mr-4 mx-auto">
      <h2 className="nhsuk-label--m">
        {translate('HOME.INFO.FAMILY_MEMBER_COMPONENT')}
      </h2>

      <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.CARE_FOR_DESC_1')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.CARE_FOR_DESC_2')}</p>
        </section>
      </div>
    </div>
  );
};

export {
  InfoComponent,
  GetHelpComponent,
  CareForComponent,
  FamilyMemberComponent,
};
