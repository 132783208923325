import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkboxes, DateInput, ErrorMessage, Hint, Label } from 'nhsuk-react-components';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import configuration from 'config';
import { translate } from 'app/core';
import { navigate } from 'app/navigation';
import ButtonComponent from 'app/shared/components/Button/Button';
import InputComponent from 'app/shared/components/Input/Input/Input';
import MultiRadioComponent from 'app/shared/components/Input/MultiRadio/MultiRadio';
import { ErrorI } from 'app/shared/interfaces/ErrorI';
import RegisterForm, { ConsentGPOptions, GendersOptions, HowYouFindOptions, RegisterFormI } from 'app/shared/models/registerForm';
import { iubendaServiceInstance, userServiceInstance } from 'app/shared/services';

import './Form.component.scss';

const FormComponent = ({describesYou}: {describesYou: string}): JSX.Element => {
  const {chUrl} = configuration;
  const gendersOptions = Object.values(GendersOptions);
  const howYouFindOptions = Object.values(HowYouFindOptions);
  const consentGPOptions = Object.values(ConsentGPOptions);

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [formData, setFormData] = useState<RegisterFormI>({} as RegisterFormI);
  const [registerForm, setRegisterForm] = useState<RegisterForm>(new RegisterForm());
  const [errorEmailExist, setErrorEmailExist] = useState<boolean>(false);
  const [errorEmailFormat, setErrorEmailFormat] = useState<boolean>(false);
  const [errorDefault, setErrorDefault] = useState<boolean>(false);

  const executeScroll = (): void => document.getElementById('register-label')?.scrollIntoView();

  const registerUser = async (newRegisterForm: RegisterForm): Promise<void> => {
    let isErrorDefault = false;
    let isErrorEmailExist = false;
    let isErrorEmailFormat = false;
    let form = newRegisterForm;
    setLoadingForm(true);
    try {
      const response: { data: { user: { id: string}}} = await userServiceInstance.registerUser(newRegisterForm);

      if (configuration.iUbendaEnabled) {
        await iubendaServiceInstance.createConsentIubenda(response.data.user.id, newRegisterForm);
      }

      if (window.gtag) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-11031067820/jvexCJG_xIMYEKz5gowp'
        });

        window.gtag('event', 'conversion', {
          'allow_custom_scripts': true,
          'send_to': 'DC-13047332/nhsyu0/regis0+standard'
        });
      }

      navigate('/registered')

    } catch(err: any) {
      const error = err?.response?.data as ErrorI;
      if (error && error.errors) {
        if (error.errors.email || error.errors.sys_first_name || error.errors.sys_last_name) {
          if (error.errors.email) {
            const [eEmail] = error.errors.email
            if (eEmail === "Email hash exists") {
              isErrorEmailExist = true;
            } else {
              isErrorEmailFormat = true;
            }
          }
          if (error.errors.sys_first_name || error.errors.sys_last_name) {
            const newForm: RegisterForm = new RegisterForm(newRegisterForm);
            if (error.errors.sys_first_name) {
              newForm.addError('firstName', 'HOME.FORM.ERROR.INVALID_CHARACTERS_FIRST_NAME');
            }
            if (error.errors.sys_last_name) {
              newForm.addError('lastName', 'HOME.FORM.ERROR.INVALID_CHARACTERS_LAST_NAME');
            }
            form = newForm
          }
        } else {
          isErrorDefault = true;
        }
        executeScroll();
      }
    }
    setErrorDefault(isErrorDefault);
    setErrorEmailExist(isErrorEmailExist);
    setErrorEmailFormat(isErrorEmailFormat);
    setRegisterForm(form);
    setLoadingForm(false);
  };

  const register = (): void => {
    const newRegisterForm: RegisterForm = new RegisterForm({
      ...formData,
      email: formData.email?.trim(),
      firstName: formData.firstName?.trim(),
      lastName: formData.lastName?.trim(),
      describesYou,
    });

    newRegisterForm.showErrors = true;
    newRegisterForm.validateForm();
    if (newRegisterForm.map.has('dateOfBirth') && newRegisterForm.map.get('dateOfBirth') === 'HOME.FORM.ERROR.UNDER_18_YEARS_OLD') {
      navigate('/age')
      return;
    }
    if (newRegisterForm.map.size === 0) {
      registerUser(newRegisterForm);
    } else if (newRegisterForm.showErrors) {
      executeScroll();
      setRegisterForm(newRegisterForm);
    }
  }

  const {showErrors, map} = registerForm;

  const emailErrors = (): string | boolean | JSX.Element => {
    let showErrorEmailFormat = errorEmailFormat;
    if (showErrors && map.has('email')) {
      if (map.get('email') !== "ERROR.EMAIL_ADDRESS_VALID") {
        return translate(map.get('email') as string);
      }
      showErrorEmailFormat = true
    } if (errorEmailExist) {
      return (
        <>
          <ErrorMessage>{translate('HOME.FORM.ERROR.EMAIL_ADDRESS_IN_USE')}</ErrorMessage>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-error-color">
            {translate('HOME.FORM.ERROR.USE_A_DIFFERENT_ADDRESS')}
            <a href={chUrl}>{chUrl}</a>
            {translate('HOME.FORM.ERROR.WITH_EXISTING_ACCOUNT')}
          </p>
        </>
      ) as JSX.Element;
    }
    if (showErrorEmailFormat) {
      return (
        <>
          <ErrorMessage>{translate('HOME.FORM.ERROR.EMAIL_FORMAT')}</ErrorMessage>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-error-color">
            {translate('HOME.FORM.ERROR.GENERIC_DESCRIPTION')}
            <a href="mailto:healthyliving@support.changinghealth.com">{translate('EMAIL_HL')}</a>
          </p>
        </>
      ) as JSX.Element;
    }
    return false
  }

  return (
    <div className='mx-auto bg-gray-200 shadow-md rounded px-2 lg:px-8 pt-6 pb-8 nhsuk-u-margin-bottom-4'>
      <h2 id="register-label" className="nhsuk-u-margin-bottom-4 nhsuk-label--m">
        {translate('HOME.FORM.REGISTER_FOR_THE_PROGRAMME')}
      </h2>

      { errorDefault
        ? (
          <>
            <ErrorMessage>{translate('HOME.FORM.ERROR.GENERIC')}</ErrorMessage>
            <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-error-color">
              {translate('HOME.FORM.ERROR.GENERIC_DESCRIPTION')}
              <a href="mailto:healthyliving@support.changinghealth.com">{translate('EMAIL_HL')}</a>
            </p>
          </>
          )
        : null}
      {
        (registerForm.showErrors && map.size > 0) || errorEmailExist || errorEmailFormat
        ? <ErrorMessage>{translate('FORM.PLEASE_CORRECT_FORM_ERRORS')}</ErrorMessage>
        : null
      }
      <form>
        <InputComponent
          id='first-name'
          error={showErrors && map.has('firstName') ? translate(map.get('firstName') as string) : false}
          onChange={(e: string): void => setFormData({...formData, firstName: e})}
          label={translate('HOME.FORM.FIRST_NAME')}
          disabled={loadingForm}
        />
        <InputComponent
          id='last-name'
          error={showErrors && map.has('lastName') ? translate(map.get('lastName') as string) : false}
          onChange={(e: string): void => setFormData({...formData, lastName: e})}
          label={translate('HOME.FORM.LAST_NAME')}
          disabled={loadingForm}
        />
        <InputComponent
          id='email'
          error={emailErrors()}
          onChange={(e: string): void => setFormData({...formData, email: e})}
          label={translate('HOME.FORM.EMAIL')}
          disabled={loadingForm}
        />
        <DateInput
          onChange={(e): void => setFormData({...formData, dateOfBirth: e.target.value})}
          error={showErrors && map.has('dateOfBirth') ? translate(map.get('dateOfBirth') as string) : false}
        >
          <fieldset>
            <legend>
              <Label className="nhsuk-label--s">{translate('HOME.FORM.DATE_OF_BIRTH')}</Label>
            </legend>
            <Hint>{translate('HOME.FORM.DATE_OF_BIRTH_EXAMPLE')}</Hint>
            <DateInput.Day error={showErrors && map.has('dayDateOfBirth') ? !!map.get('dayDateOfBirth') : false} disabled={loadingForm} />
            <DateInput.Month error={showErrors && map.has('monthDateOfBirth') ? !!map.get('monthDateOfBirth') : false} disabled={loadingForm} />
            <DateInput.Year error={showErrors && map.has('yearDateOfBirth') ? !!map.get('yearDateOfBirth') : false} disabled={loadingForm} />
          </fieldset>
        </DateInput>
        <MultiRadioComponent
          id='gender'
          label={translate('HOME.FORM.GENDER')}
          options={gendersOptions}
          error={showErrors && map.has('gender') ? translate(map.get('gender') as string) : false}
          detailsText={translate('HOME.FORM.GENDER_POSTCODE_DESCRIPTION')}
          onChange={(e: string): void => setFormData({...formData, gender: e})}
          disabled={loadingForm}
        />
        <InputComponent
          id='postcode'
          detailsText={translate('HOME.FORM.GENDER_POSTCODE_DESCRIPTION')}
          error={showErrors && map.has('postcode') ? translate(map.get('postcode') as string) : false}
          onChange={(e: string): void => setFormData({...formData, postcode: e.trim()})}
          label={translate('HOME.FORM.POSTCODE')}
          disabled={loadingForm}
        />
        <MultiRadioComponent
          id='how-find'
          label={translate('HOME.FORM.HOW_FIND_OUT_ABOUT_HL')}
          options={howYouFindOptions}
          error={showErrors && map.has('findOutAbout') ? translate(map.get('findOutAbout') as string) : false}
          onChange={(e: string): void => setFormData({...formData, findOutAbout: e})}
          disabled={loadingForm}
        />
        <Checkboxes
          className='flex-column'
          error={showErrors && map.has('agree') ? translate(map.get('agree') as string) : undefined}
        >
          <Checkboxes.Box
            value='agree'
            onChange={(e: FormEvent<HTMLInputElement>): void => setFormData({...formData, agree: (e.target as HTMLInputElement).checked})}
            disabled={loadingForm}
          >
            <span className="nhsuk-u-font-weight-bold nhsuk-u-margin-bottom-1">{translate('HOME.FORM.I_AGREE_TO')}</span>
            &nbsp;(links open in a new window)
            <span className="nhsuk-u-font-weight-bold">:</span>
          </Checkboxes.Box>
          <ul className="nhsuk-list nhsuk-list--bullet nhsuk-u-margin-left-4">
            <li>
              {translate('HOME.FORM.CHANGING_HEALTH')}
              <a href='https://www.changinghealth.com/policies/privacy-policy/' target='_blank' rel='noopener noreferrer'>
                <span>{translate('HOME.FORM.PRIVACY_POLICY')}</span>
                <span className="visually-hidden">{translate('OPENS_IN_NEW_TAB')}</span>
              </a>
            </li>
            <li>
              {translate('HOME.FORM.CHANGING_HEALTH')}
              <a href='https://www.changinghealth.com/policies/terms-and-conditions/' target='_blank' rel='noopener noreferrer'>
                <span>{translate('HOME.FORM.TERMS_CONDITIONS')}</span>
                <span className="visually-hidden">{translate('OPENS_IN_NEW_TAB')}</span>
              </a>
            </li>
            <li>
              {translate('HOME.FORM.HEALTHY_LIVING')}
              <Link className="nhsuk-link" to="/policies/healthy-living-fair-processing-notice" target='_blank'>{translate('HOME.FORM.FAIR_PROCESSING_NOTICE')}</Link>
            </li>
            <li>
              {translate('HOME.FORM.HEALTHY_LIVING')}
              <Link className="nhsuk-link" to="/policies/service-description-healthy-living" target='_blank'>
                {translate('HOME.FORM.SERVICE_DESCRIPTION')}
              </Link>
            </li>
          </ul>
        </Checkboxes>
        <MultiRadioComponent
          id='gp'
          label={translate('HOME.FORM.CONSENT_GP')}
          options={consentGPOptions}
          error={showErrors && map.has('consentGP') ? translate(map.get('consentGP') as string) : false}
          hintText={translate('HOME.FORM.CONSENT_GP_DESCRIPTION')}
          onChange={(e: string): void => setFormData({...formData, consentGP: e})}
          disabled={loadingForm}
        />
        <div className='text-left container-btn-register'>
          <ButtonComponent className="nhsuk-u-margin-bottom-0" text={translate('HOME.FORM.REGISTER')} onClick={(): void => register()} disabled={loadingForm} />
          {loadingForm && (
            <span className='nhsuk-u-margin-left-2'>
              <FontAwesomeIcon
                size="2x"
                icon={faSpinner}
                pulse
              />
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
