import React from 'react';
import { translate } from "app/core"

const FeedbackAboutTheProgrammeSection = (): JSX.Element => {
    return(
      <div className="mx-auto bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mt-8 mb-2">
        <h2 className="nhsuk-label--m">
          {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME')}
        </h2>
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_INTRO')}
          </p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            <i>{translate('HOME.INFO.HEALTHY_LIVING_MENTION_1')}</i>
          </p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            <i>{translate('HOME.INFO.HEALTHY_LIVING_MENTION_2')}</i>
          </p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            <i>{translate('HOME.INFO.HEALTHY_LIVING_MENTION_3')}</i>
          </p>
        </section>
      </div>
    )
}

export default FeedbackAboutTheProgrammeSection;
