export interface Environment {
  development: boolean;
  apiUrl: string;
  imagesUrl: string;
  useMocks: boolean;
  secretKeyBase: string;
  changingHealthToken: string;
  unitId: number;
  packageId: number;
  knowdiabetesUnitId: number;
  knowdiabetesPackageId: number;
  geoIpEnabled: boolean;
  iUbendaEnabled: boolean;
  iUbendaApiKey: string;
  chUrl: string;
}

interface Environments {
  dev: Environment;
  staging: Environment;
  prod: Environment;
  secretKeyBase?: string;
  changingHealthToken?: string;
  unitId?: number;
  packageId?: number;
  knowdiabetesUnitId?: number;
  knowdiabetesPackageId?: number;
}

const environment: Environments = {
  dev: {
    development: true,
    apiUrl: 'https://api.dev.changinghealth.com/api/ext/user',
    useMocks: false,
    secretKeyBase: '',
    changingHealthToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiaWF0IjoxNjQ5MDc4OTEyLCJleHAiOjE5NjQ0Mzg5MTIsIm5iZiI6MTY0OTA3ODkxMiwianRpIjoiejk2MUNUUldKWnRlMjJ3dSIsImFwcF9pZCI6IjE2IiwiaXAiOltdfQ.CAj-FagMB1B1YosFf1R0hprS1P--n_UXpiQEoot7zKU',
    unitId: 31,
    packageId: 79,
    geoIpEnabled: false,
    iUbendaEnabled: true,
    iUbendaApiKey: 'NfCN6O3Y4rgpYlpUg9o1CGdtWh0T6V0H',
    chUrl: 'https://app.dev.changinghealth.com',
  } as Environment,
  staging: {
    development: false,
    apiUrl: 'https://api.stg.changinghealth.com/api/ext/user',
    useMocks: false,
    secretKeyBase: '',
    changingHealthToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiaWF0IjoxNjQ5MDc4OTEyLCJleHAiOjE5NjQ0Mzg5MTIsIm5iZiI6MTY0OTA3ODkxMiwianRpIjoiejk2MUNUUldKWnRlMjJ3dSIsImFwcF9pZCI6IjE2IiwiaXAiOltdfQ.CAj-FagMB1B1YosFf1R0hprS1P--n_UXpiQEoot7zKU',
    unitId: 31,
    packageId: 79,
    geoIpEnabled: false,
    iUbendaEnabled: true,
    iUbendaApiKey: 'ILO3z6yKJKqupppjgs3J5XISU8u0Zay4',
    chUrl: 'https://app.stg.changinghealth.com',
  } as Environment,
  prod: {
    development: false,
    apiUrl: 'https://api.changinghealth.com/api/ext/user',
    useMocks: false,
    secretKeyBase: '',
    changingHealthToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiaWF0IjoxNjQzNjI4MDIxLCJleHAiOjE5NTg5ODgwMjEsIm5iZiI6MTY0MzYyODAyMSwianRpIjoiM1M0OWIyaElQSG1mcVI4SCIsImFwcF9pZCI6IjIxIiwiaXAiOltdfQ.2d49_zI0BkCXl1GFbhBSqqf93sheT9FRKnvvw_fABkQ',
    unitId: 33,
    packageId: 75,
    geoIpEnabled: true,
    iUbendaEnabled: true,
    iUbendaApiKey: 'DcFUq13fgyV2CVybC5DUUE4BNfTBgJvP',
    chUrl: 'https://app.changinghealth.com',
  } as Environment,
};

export default environment;
